<template>

    <svg width="38px" height="37px" viewBox="0 0 38 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
        <title>close</title>
        <desc>Created with Sketch.</desc>
        <defs>
            <path d="M38.0770578,37.1774678 C43.934575,43.0349851 44.0734879,52.392984 38.3873284,58.0791436 C32.7011688,63.7653031 23.3431698,63.6263902 17.4856526,57.768873 C11.6281354,51.9113557 11.4892225,42.5533567 17.175382,36.8671972 C22.8615415,31.1810377 32.2195405,31.3199506 38.0770578,37.1774678 Z M19.0242227,38.7160379 C14.3601464,43.3801142 14.4745863,51.0894673 19.3198223,55.9347033 C24.1650583,60.7799393 31.8744114,60.8943792 36.5384877,56.2303029 C41.202564,51.5662266 41.0881241,43.8568735 36.2428881,39.0116375 C31.397652,34.1664015 23.688299,34.0519616 19.0242227,38.7160379 Z M23.7858096,41.3994021 L23.907196,41.488643 L24.0211185,41.5916133 L27.781,45.3508152 L31.4005598,41.7326454 C31.9863463,41.146859 32.9360937,41.146859 33.5218802,41.7326454 C34.0710549,42.2818202 34.1053784,43.1508826 33.6248504,43.7400433 L33.5218802,43.8539658 L29.902,47.4728152 L33.6629122,51.2334071 C34.2486987,51.8191936 34.2486987,52.768941 33.6629122,53.3547275 C33.1137375,53.9039022 32.2446751,53.9382257 31.6555143,53.4576977 L31.5415919,53.3547275 L27.781,49.5938152 L24.1621505,53.2136954 C23.5763641,53.7994818 22.6266166,53.7994818 22.0408302,53.2136954 C21.4916554,52.6645206 21.457332,51.7954582 21.9378599,51.2062974 L22.0408302,51.092375 L25.659,47.4728152 L21.8997981,43.7129337 C21.3140117,43.1271472 21.3140117,42.1773998 21.8997981,41.5916133 C22.4123612,41.0790502 23.203582,41.0149798 23.7858096,41.3994021 Z" id="path-1"></path>
            <filter x="-23.7%" y="-16.9%" width="147.4%" height="147.4%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="#2A2B75" class="shadowclosestroke" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Details_Bundles" transform="translate(-1381.000000, -350.000000)" fill-rule="nonzero">
                <g id="Group-2" transform="translate(1372.000000, 319.000000)">
                    <g id="close">
                        <use fill="none" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                        <use fill-opacity="0.8" fill="#2A2B75" class="shadowclosesfill" xlink:href="#path-1"></use>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    </template>
    <script>
    
    export default{
    
      name :"shadowclosepopup"
    }
    </script>
    
    <style  lang="scss">
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";
    
    .shadowclosestroke{
      stroke:$clr-shadow-close-popupstroke;
    }
    .shadowclosesfill{
      fill: $clr-shadow-close-popupfill;
    }
    
    </style>